import React from 'react'
import { graphql } from 'gatsby'

import Fade from '../components/fade'
import Layout from '../components/layout'
import Player from '../components/player'
import close from '../assets/close.svg'
import {pickQuality} from "../../utils/pick-quality";

class WorkDetail extends React.Component {

  constructor(props) {
    super(props)
    this.video = React.createRef()
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.video = this.video.current
    }
  }

  componentWillUnmount() {}

  render() {
    const { backHome, backDirector } = this.props.pageContext
    return (
      <Layout page={this} modifiers={['work-detail']}>
        <div className="work-detail">
          <Player modifiers={['fullscreen']} className="absolute pin-t h-full w-full" cover={`${this.props.data.workpiece.cover.url}?auto=format&fit=min&w=1600`} src={pickQuality(this.props.data.workpiece)} ref={this.video}>
            <div className="wrapper relative z-1">
              <div className="flex justify-between mt-40">
                <div className="p-16"></div>
                <div className="text-center">
                  <h1 className="font-head text-24 lg:text-28 uppercase tracking-wide leading-tight">{this.props.data.workpiece.title}</h1>
                  <span className="font-main text-12 lg:text-13 uppercase leading-tight">{this.props.data.workpiece.subtitle}</span>
                </div>
                <div className="player__close-btn">
                  <Fade to={ backHome ? `/` : backDirector ? `/directors/${this.props.data.workpiece.director.slug}` : `/work/` }><img src={close} alt="close"/></Fade>
                </div>
              </div>
            </div>
          </Player>
        </div>
      </Layout>
    )
  }
}

export default WorkDetail

export const query = graphql`
  query WorkDetailQuery($slug: String!) {
    workpiece: datoCmsWorkpiece(slug: { eq: $slug }) {
      id
      title
      subtitle
      director {
        slug
      }
      slug
      mp4Url
      mp4UrlSd
      cover {
        url
      }
    }
  }
`;
